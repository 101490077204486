.botao-opcao{
  padding: 0.5rem 0.7rem;
  text-align: center;
  cursor: pointer;
  color: white;
  height: 100%;
  display: grid;
  border-radius: 12px;
  background-color: #009b97 !important;
  img{
    margin: auto;
    height: 70%;
  }
  .texto{
    .titulo{
      font-size: 1rem;
    }
  }
}

@media (max-width: 600px) {
  .botao-opcao{
   
    .texto{
      .titulo{
        font-size: 1rem;
      }
    }
  }
  
}
