
.botao-editar-tabela{
  margin: 5px;
  cursor: pointer;
}
.botoes-editar-tabela{
  text-align: right;
}
.titulo-tela{
  color: #009b97 
}