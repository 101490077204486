

.sessao-aceite-termos-uso{
    width: 100%;
    max-width: 100%;
    color: #2E302E;
    padding-top:2rem;
    padding-bottom: 2rem;

   
    .subtitulo{
        width: 100%;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
    }
    .descricao{
        margin-bottom: 2rem;
        font-size: 0.8rem;
    }
    
}