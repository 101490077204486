.botao-escolha-tipo-pagamento{
  display: flex;
  padding: 1rem;
  margin-bottom: 0.4rem;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  background-color: #009b97 !important;
  max-width: 25rem;
  img{
    margin: auto 0.5rem auto;
    max-height: 2.5rem;
  }
  .texto{
    margin-left: 0.5rem;
    margin-top: auto;
    text-align: left;
    margin-bottom: auto;
    .titulo{
      padding-bottom: 0.1rem;
      font-size: 1.2rem;
    }
  }
}