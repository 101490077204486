.header{
  background-color: #2e312E  !important;
  color: black !important;
}
.header-item{
  color: #03d8d3 !important;
  font-size: 0.95rem !important;
}
.header-item-entrar{
  border: solid;
  border-width: 1px;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
}
.logo-header{
  max-width: 80px;
  margin-right: 3rem;
}