.botao-inicio {
  color: #009b97;
}

@media (max-width: 600px) {
  .botao-inicio {
    display: none;
    color: #009b97;
  }
}
